body {
  margin: 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: darkgrey;
}

/* hide scrollbar style start */
html {
  overflow: scroll;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
}
/* hide scrollbar style end */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list {
  display: flex;
  justify-content: center;
  width: 100%;
}

.list ul li {
  list-style: none;
  margin: auto;
  text-align: center;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 600px) {
  .toast {
      padding: 6px 12px;
      font-size: 12px;
      max-width: 200px;
  }
}

.phone-number {
  font-family: monospace;
  line-height: 1.2;
}

.phone-number span {
  vertical-align: middle;
}
